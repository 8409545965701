<template>
  <el-dialog :close-on-click-modal="false" title="结转汇兑损益" class="upload button_bg" v-model="dialogBox" width="40%"  destroy-on-close>
    <el-form label-width="180px"  label-position="right" :model="param">
      <el-form-item label="汇兑收益会计科目：">
        <subject-list :codes="$findCode(code)" v-model:subjectId="param.subjectId" placeholder='请选择汇兑收益会计科目' class="subject_box"></subject-list>
      </el-form-item>
      <el-form-item label="汇兑损失会计科目：">
        <subject-list :codes="$findCode(code)" v-model:subjectId="param.subjectId2" placeholder='请选择汇兑损失会计科目' class="subject_box"></subject-list>
      </el-form-item>
      <el-form-item v-for="(item,index) in param.wbs" :key="index" :label="item.label + '：'" :value="item.id">
        <qzf-input v-model:num="item.rate" placeholder="" size="mini" style="width:312px"></qzf-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogBox = false" size="mini">取 消</el-button>
        <el-button type="primary" size="mini" @click="saveFx('ruleForm')">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { wbList } from '@/api/invoice'
import { voucherFx } from '@/api/carryover'

export default {
  props: {
  },
  data() {
    return {
      dialogBox: false,
      param:{
        subjectId:'',
        subjectId2:'',
        wbs:[]
      },
      rate:'',
      code:['code5301','code5603'],

    };
  },
  created () {
    this.getHulv()
  },
  methods: {
    // 汇兑损益
    saveFx(){
      if(this.param.subjectId == ''){
        this.$message({
          message: '请选择汇兑收益会计科目',
          type: 'warning'
        });
        return
      }
      if(this.param.subjectId2 == ''){
        this.$message({
          message: '请选择汇兑损失会计科目',
          type: 'warning'
        });
        return
      }
      voucherFx(this.param).then((res) => {
        this.dialogBox = false
        if(res.data.msg != 'success'){
          this.dialogBox = false
          return
        }
        this.$qzfMessage("操作成功")
        this.$emit("handleSuccess");
      })
      
    },
    // 汇率
    getHulv(){
      wbList({}).then(res => {
        this.param.wbs = res.data.data.list;
      });
    }

  }
};
</script>



<style>
.subject_box .el-select{
  width: 312px !important;
}
</style>
