<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="手动合并" v-model="dialogHebing"  class="button_bg">
    <!-- 1111 -->
    <el-form label-width="130px">
      <el-form-item label="合并分录：">
        <el-radio v-model="radioStatus" :label="0">合并排序（分录合并且排序）</el-radio>
      </el-form-item>
      <el-form-item label="">
        <el-radio v-model="radioStatus" :label="1">不合并排序（分录不合并，借贷方排序）</el-radio>
      </el-form-item>
      <el-form-item label="">
        <el-radio v-model="radioStatus" :label="3">不合并不排序（分录不合并，借贷方不排序）</el-radio>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogHebing = false" size="small">取 消</el-button>
        <el-button type="primary" @click="voucherHebing" size="small">确 定</el-button>
      </div>
    </template>
    
  </el-dialog>
</template>

<script>
import { HandMergeVoucher } from '@/api/voucher.js'
export default {
  name: 'manualMerge',
  data() {
    return {
      dialogHebing:false,
      radioStatus: 0,
    }
  },
  props:{
    allselect:{
      type: String,
    }
  },
  methods:{
    dialogUp() {
      this.dialogHebing = true
    },
    // 手动合并
    voucherHebing(){
      HandMergeVoucher({ids:this.allselect,type:this.radioStatus}).then(res => {
        if(res.data.msg != 'success'){
          this.dialogHebing = false;
          this.$message.error(res.data.msg)
          return
        }
        this.$message({
          message: "已完成",
          type: "success"
        });
        this.$emit("success")
        this.dialogHebing = false;
      });
    },
  }
}
</script>

<style>

</style>