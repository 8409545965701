<template>
  <div>
    <el-dialog :close-on-click-modal="false" v-model="dialogTableVisible" >
      <el-table :data="gridData" border ref="mainTable" @selection-change="handleSelectionChange" >
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column property="name" label="名称" width="200" />
        <el-table-column property="status" label="状态" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.status == '未开始'">
              <i class="iconfont icon-gantanhao"></i>
              <span>{{scope.row.status}}</span>
            </div>
            <div class="item_icon" v-else-if="scope.row.status == '进行中'">
              <i class="iconfont icon-info"></i>
              <span>{{scope.row.status}}</span>
            </div>
            <div class="item_icon" v-else-if="scope.row.status == '完成'">
              <i class="iconfont icon-duihao"></i>
              <span>{{scope.row.status}}</span>
            </div>
            <div class="item_icon" v-else>
              <i class="iconfont icon-cuowu"></i>
              <span>{{scope.row.status}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="startTask" v-loading="isLoading">
            一键生成凭证
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {  oneKeyVoucher, voucherSort } from "@/api/voucher.js";
import { salaryCarryOver,carryOverAsset } from '@/api/carryover.js'
import {  taxCreate ,createKj} from "@/api/taxCalculation"
import { profitAndLoss } from '@/api/carryover.js'



export default {
  name: 'oneKeyVoucher',
  data() {
    return {
      gridData:[
        {
          name: "清单凭证",
          status: "未开始"
        },
        {
          name: "计提/发放工资",
          status: "未开始"
        },
        {
          name: "资产折旧",
          status: "未开始"
        },
        {
          name: "计提税金(税务报表取数)",
          status: "未开始"
        },
        {
          name: "结转损益(财务报表取数)",
          status: "未开始"
        },
        // {
        //   name: "财务报表取数",
        //   status: "未开始"
        // },
        
      ],
      dialogTableVisible:false,
      multipleSelection: [],
      isLoading:false
    };
  },
  created(){
  },
  mounted() {
    
  },

  methods: {
    async startTask(){
      if(this.multipleSelection.length == 0){
        this.$qzfMessage('请至少选择一项',1)
        return
      }
      this.isLoading = true
      if(this.getIs("清单凭证")){
        this.gridData[0].status = '进行中'
        await oneKeyVoucher({}).then(res => {
          if(res.data.msg == 'success'){
            this.gridData[0].status = '完成'

            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('invioceFeeUpdate')


          }else{
            this.gridData[0].status = res.data.msg
          }
        })
      }
      if(this.getIs("计提/发放工资")){
        this.gridData[1].status = '进行中'

        await salaryCarryOver({}).then(res => {
          if(res.data.msg == 'success'){
            this.gridData[1].status = '完成'
            
            this.$bus.emit('salarySheetUpdate')

          }else{
            this.gridData[1].status = res.data.msg
          }
        })
      }
      if(this.getIs("资产折旧")){
        this.gridData[2].status = '进行中'

        await carryOverAsset({}).then(res => {
          if(res.data.msg == 'success'){
            this.gridData[2].status = '完成'

            this.$bus.emit('assetsUpdate')
            this.$bus.emit('bankUpdate')
            this.$bus.emit('cashUpdate')
            this.$bus.emit('billUpdate')
            this.$bus.emit('inventoryManageUpdate')
            this.$bus.emit('inventoryPeriodUpdate')

          }else{
            this.gridData[2].status = res.data.msg
          }
        })
      }
      if(this.getIs("计提税金(税务报表取数)")){
        this.gridData[3].status = '进行中'

        await taxCreate({}).then(res => {
          if(res.data.msg == 'success'){
            this.gridData[3].status = '完成'

            this.$bus.emit("financeUpdate")
          }else{
            this.gridData[3].status = res.data.msg
          }
        })
      }
      if(this.getIs("结转损益(财务报表取数)")){
        this.gridData[4].status = '进行中'
        //断号重排一下
        await voucherSort({})

        await profitAndLoss({}).then(res => {
          if(res.data.msg == 'success' && res.data.data.status){
            this.gridData[4].status = '完成'
          }else{
            if(res.data.msg != 'success'){
              this.gridData[4].status = res.data.msg
            } else {
              let msg = ""
              res.data.data.list.map(v=>{
                if(v.match('错误')){
                  msg += v
                }
              })
              this.gridData[4].status = msg
            }
          }
        })
      }

      // 财务报表取数
      // if(this.getIs("财务报表取数")){
      //   this.gridData[5].status = '进行中'
      //   let param = {
      //     zcfz:1,
      //     lrb:1,
      //     xjllb:1
      //   }
      //   await createKj(param).then(res => {
      //     this.$bus.emit("taxUpdate")
      //     if(!res.data.data.subjectStatus && !res.data.data.zcLessThen0 && !res.data.data.zcfzStatus){
      //       this.gridData[5].status = '完成'
      //     }else{
      //       if(res.data.data.subjectStatus == true){
      //         this.gridData[5].status = '银行现金金额小于0'
      //       }
      //       if(res.data.data.zcLessThen0 == true){
      //         this.gridData[5].status = '银行现金金额小于0'
      //       }
      //       if(res.data.data.zcfzStatus == true){
      //         this.gridData[5].status = '资产负债不平衡'
      //       }
      //     }
      //   })
      // }
      this.$bus.emit('voucherUpdate')
      this.isLoading = false
    },
    getIs(type){
      let staus = false
      this.multipleSelection.map(v=>{
        if(type == v.name){
          staus = true
          return
        }
      })
      return staus
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    openDialog(){
      this.dialogTableVisible = true 
      this.$nextTick(()=>{
        if(this.multipleSelection.length  == 0){
          this.$refs.mainTable.toggleAllSelection()
        }
      })

    }
  },
};
</script>

<style lang="scss" scoped>
.item_icon{
  display: inline-block;
  width: 58%;
  padding: 1px 3px;
  padding-right: 10px;
  
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  span{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
</style>