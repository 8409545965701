<template>
  <div>
    <el-dialog :close-on-click-modal="false" v-model="dialogTableVisible" >
      <div class="table-big">
        <div class="table">
          <div class="tableHead">凭证字号</div>
          <div class="tableHead">日期</div>
          <div class="tableHead">摘要</div>
          <div class="tableHead4">科目</div>
          <div class="tableHead">借方</div>
          <div class="tableHead">贷方</div>
          <div class="tableHead">附件张数</div>
          <div class="tableHead">凭证来源</div>
          <div class="tableHead">制作人</div>
          <div class="tableHead">审核人</div>
          <div class="tableHead">审核状态</div>
        </div>
        <draggable 
          v-model="list" 
          group="people" 
          @start="drag=true" 
          @end="drag=false" 
          item-key="id">
          <!-- <template #item="{element,index}">
            <div style="height: 40px;border:1px solid #ccc">
              {{element.voucherNo}} 
              <span></span>
            </div>
          </template> -->
          <template #item="{element,index}">
            <div class="table-div" style="width:2000px !important;border-top: 1px solid #eee;">
              <div class="tableHead3"> {{element.voucherNo}} </div>
              <div class="tableHead3"> {{ $parseTime(element.voucherDate, "{y}-{m}-{d}")}} </div>
              <div class="tableHead3">
                <div v-for="(item2,index2) in element.voucherItem" :key="index2">{{item2.summary}}</div>
              </div>
              <div class="tableHead2">
                <div v-for="(item2,index2) in element.voucherItem" :key="index2">{{item2.subjectName}}</div>
              </div>
              <div class="tableHead3">
                <div v-for="(item2,index2) in element.voucherItem" :key="index2">{{item2.inAmount}}</div>
              </div>
              <div class="tableHead3">
                <div v-for="(item2,index2) in element.voucherItem" :key="index2">{{item2.outAmount}}</div>
              </div>
              <div class="tableHead3"> {{element.billCount}} </div>
              <div class="tableHead3"> {{element.fromType}} </div>
              <div class="tableHead3">{{element.createName}}</div>
              <div class="tableHead3">{{element.checkName}}</div>
            </div>
          </template>
        </draggable>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="startTask" v-loading="isLoading">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { voucherList ,dragSort } from "@/api/voucher.js";
import draggable from 'vuedraggable'
export default {
  name: 'oneKeyVoucher',
  data() {
    return {
      drag: false,
      list: [
        {name: 11},{name: 22},{name: 33}
      ],
      dialogTableVisible:false,
      listQuery:{
        comId: this.$store.getters['user/comInfo'].comId * 1,
        orderBy: " replace(voucher_no,'记','')+0",
        checkStatus:0
      },
    };
  },
  created(){
  },
  components: {
    draggable,
  },
  created(){
  },
  mounted() {
    
  },
  methods: {
    getList(){
      voucherList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
          this.dialogTableVisible = true
        }
      })
    },
    async startTask(){
      let listNew = []
      this.list.map(v => {
        listNew.push({
          id:v.id
        })
      })
      dragSort(listNew).then(res => {
        if(res.data.msg == 'success'){
          this.$qzfMessage("排序成功")
          this.dialogTableVisible = false
          this.$emit('success')
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.table-big{
      width: 100%;
      height: 620px;
      overflow: auto;
      // border: 1px solid #eee;
      margin-top: 10px;
    .table{
        min-width: 2000px;
        .table-div{
            display: inline-block;
        }
    }
    
  }
  .tableHead3{
    display:inline-block;
    width:100px;
    text-align:center;
    border-right:1px solid #eee;
    height:80px;
    overflow-x: auto;
  }
  .tableHead{
    display:inline-block;
    width:100px;
    text-align:center;
    border-right:1px solid #eee;
    height:30px;
  }
  .tableHead4{
    display:inline-block;
    width:360px;
    text-align:center;
    border-right:1px solid #eee;
    height:30px;
  }
.tableHead2{
  display:inline-block;
	width:360px;
	text-align:center;
	border-right:1px solid #eee;
	height:80px;
  overflow-x: auto;
}
</style>