import request from '@/utils/request'

// 结转损益
export function profitAndLoss(data) {
  return request({
      url: 'api/v2/carryover/profitAndLoss',
      method: 'post',
      data
  })
}
// 结转资产
export function carryOverAsset(data) {
  return request({
      url: 'api/v2/carryover/carryOverAsset',
      method: 'post',
      data
  })
}
// 结转工资
export function salaryCarryOver(data) {
  return request({
      url: 'api/v2/carryover/salaryCarryOver',
      method: 'post',
      data
  })
}

//结账
export function checkOut(data) {
  return request({
      url: 'api/v2/carryover/checkOut',
      method: 'post',
      data
  })
}

//反结账
export function reverseCheckout(data) {
  return request({
      url: 'api/v2/carryover/reverseCheckout',
      method: 'post',
      data
  })
}

//结转汇兑损益
export function voucherFx(data) {
  return request({
      url: 'api/v2/carryover/voucherFx',
      method: 'post',
      data
  })
}

//init
export function carrOverList(data) {
  return request({
      url: 'api/v2/carryover/carrOverList',
      method: 'post',
      data
  })
}