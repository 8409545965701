<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="复制或冲红凭证" v-model="dialogFormVisible">
    <el-form>
      <el-form-item label="类型">
        <el-radio v-model="copyVoucherParam.type" :label="1">复制</el-radio>
        <el-radio v-model="copyVoucherParam.type" :label="2">冲红</el-radio>
      </el-form-item>
      <el-form-item label="账期">
        <qzf-period v-model:period="copyVoucherParam.period" @success="getList"></qzf-period>
        <!-- <el-select v-model="copyVoucherParam.period" placeholder="请选择活动区域">
          <el-option v-for="(item,index) in periodList" :key="index" :label="item.period" :value="item.period"></el-option>
        </el-select> -->
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveVoucherCopy" size="small">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { saveEaVoucherCopy } from "@/api/voucher.js";
export default {
  name: 'duplicate',
  data() {
    return {
      dialogFormVisible: false,
      copyVoucherParam:{
        id: 0,
        type: 1,
        period: this.$store.getters['user/comInfo'].period
      },
    }
  },
  methods: {
    init(row) {
      this.copyVoucherParam.id = row
      this.dialogFormVisible = true
      console.log(this.copyVoucherParam,'this.copyVoucherParam')
    },
    saveVoucherCopy(){
      // this.copyVoucherParam.id = this.copyId
      // console.log(this.copyVoucherParam)
      saveEaVoucherCopy(this.copyVoucherParam).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
            message: '复制成功',
            type: 'success'
          });
          this.dialogFormVisible = false
          this.$emit('success')
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
  }
}
</script>

<style>

</style>