<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="习惯设置" v-model="dialogFormVisibleSetting"  class="button_bg">
      <el-form ref="dataForm"  label-position="right">
        <el-form-item>
          <el-tabs tab-position="left" style="height: 360px;" v-model="activeName">
            <el-tab-pane label="进项发票" name="first">
              <div class="right_title">
                <h5>凭证习惯</h5>
                <p>*温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！</p>
                <el-radio-group v-model="mergeSetInfo.in">
                  <div class="item_radio">
                    <el-radio label='0'>按单张发票生成凭证</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label='1'>按相同往来单位合并凭证</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label='2'>按业务类型合并凭证</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label='3'>按时间合并凭证</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label='4'>按往来单位和时间合并凭证</el-radio>
                  </div>
                  <div class="one_item">
                    <el-checkbox v-model="mergeSetInfo.inEntry">结算分录合并一条</el-checkbox>
                  </div>
                </el-radio-group>
                <h5>摘要自定义</h5>
                <p>*温馨提示：自定义凭证摘要不可合并凭证！</p>
                <el-checkbox-group v-model="mergeSetInfo.inSummaryArr">
                  <el-checkbox label="默认业务类型" checked disabled></el-checkbox>
                  <el-checkbox label="开票日期"></el-checkbox>
                  <el-checkbox label="单位名称"></el-checkbox>
                  <el-checkbox label="开票项目"></el-checkbox>
                  <el-checkbox label="金额"></el-checkbox>
                  <el-checkbox label="发票号码"></el-checkbox>
                </el-checkbox-group>
              </div>
            </el-tab-pane>
            <el-tab-pane label="销项发票" name="second">
               <div class="right_title">
                <h5>凭证习惯</h5>
                <p>*温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！</p>
                <el-radio-group v-model="mergeSetInfo.out">
                  <div class="item_radio">
                    <el-radio label='0'>按单张发票生成凭证</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label='1'>按相同往来单位合并凭证</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label='2'>按业务类型合并凭证</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label='3'>按时间合并凭证</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label='4'>按往来单位和时间合并凭证</el-radio>
                  </div>
                  <div class="one_item">
                    <el-checkbox v-model="mergeSetInfo.outEntry">结算分录合并一条</el-checkbox>
                  </div>
                </el-radio-group>
                <h5>摘要自定义</h5>
                <p>*温馨提示：自定义凭证摘要不可合并凭证！</p>
                <el-checkbox-group v-model="mergeSetInfo.outSummaryArr">
                  <el-checkbox label="默认业务类型" checked disabled></el-checkbox>
                  <el-checkbox label="开票日期"></el-checkbox>
                  <el-checkbox label="单位名称"></el-checkbox>
                  <el-checkbox label="开票项目"></el-checkbox>
                  <el-checkbox label="金额"></el-checkbox>
                  <el-checkbox label="发票号码"></el-checkbox>
                  <el-checkbox label="是否代开"></el-checkbox>
                </el-checkbox-group>

              </div>
            </el-tab-pane>
            <el-tab-pane label="银行" name="third">
              <div class="right_title none-border">
                <h5>凭证习惯</h5>
                <p>*温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！</p>
                <el-radio-group v-model="mergeSetInfo.bank">
                  <div class="item_radio">
                    <el-radio label="0">单张生成</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label="1">合并生成 按业务类型（会计科目）</el-radio>
                  </div>
                  <div class="item_radio">
                    <el-radio label="3">合并生成 按业务类型（会计科目一级）</el-radio>
                  </div>
                   <div class="item_radio">
                    <el-radio label="2">合并生成 按时间</el-radio>
                  </div>
                </el-radio-group>
                <!-- <div class="one_item">
                  合并分录
                </div> -->
                <div class="item_lists">
                  <el-checkbox v-model="mergeSetInfo.bankEntry">结算银行存款分录合并一条</el-checkbox>
                  <el-checkbox v-model="mergeSetInfo.dealingsEntry">结算往来单位分录合并一条</el-checkbox>
                  <!-- <el-checkbox-group v-model="mergeSetInfo.bankInfo">
                    <el-checkbox label="银行存款"></el-checkbox>
                    <el-checkbox label="往来科目"></el-checkbox>
                  </el-checkbox-group> -->
                </div>

                <h5>摘要自定义</h5>
                <p>*温馨提示：自定义凭证摘要不可合并凭证！并且银行记录需要每条银行对应一条业务，否则摘要可能显示有误</p>
                <el-checkbox-group v-model="mergeSetInfo.bankSummaryArr">
                  <el-checkbox label="默认业务类型" checked disabled></el-checkbox>
                  <el-checkbox label="开票日期"></el-checkbox>
                  <el-checkbox label="单位名称"></el-checkbox>
                  <el-checkbox label="金额"></el-checkbox>
                </el-checkbox-group>
              </div>
            </el-tab-pane>
            <el-tab-pane label="制作人" name="four">
              <div class="right_title">
                <h5>制作人审核人</h5>
                <p>*温馨提示：为空默认登录人！</p>
                
                <div style="margin-bottom:20px;">
                  制作人：<el-input style="width:200px;" v-model="mergeSetInfo.createUser" placeholder="制作人" size="small"></el-input>
                  隐藏：
                  <el-switch :active-value = 1 :inactive-value = 0 v-model="mergeSetInfo.createUserStatus"></el-switch>
                  （仅在打印时）
                </div>
                <div>审核人：<el-input style="width:200px;" v-model="mergeSetInfo.examUser" placeholder="审核人" size="small"></el-input>
                  隐藏：
                  <el-switch :active-value = 1 :inactive-value = 0 v-model="mergeSetInfo.examUserStatus"></el-switch>
                  （仅在打印时）
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="凭证生成时间" name="five">
              <div class="right_title">
                <h5>凭证生成时间</h5>
                <p>*温馨提示：默认为开票日期！</p>
                <div style="margin-bottom:20px;">
                  <el-radio-group v-model="mergeSetInfo.timeStatus">
                    <el-radio :label="0">默认</el-radio>
                    <el-radio :label="1">月末</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>  
      </el-form>
      <template #footer>
        <el-button @click="dialogFormVisibleSetting = false" size="small">取消</el-button>
        <el-button @click="mergeOk" type="primary" size="small">确定</el-button>
      </template>
    </el-dialog>
</template>

<script>
import { mergeSet , mergeInfo } from '@/api/voucher'
export default {
  name:'habitualSetting',
  data() {
    return {
      dialogFormVisibleSetting:false,
      activeName: 'first',
      checked: true,
      mergeSetInfo: {
        timeStatus: 0,
        in: 1,
        out: 1,
        bank: 1,
        outSummaryArr: [],
        inSummaryArr: [],
        bankSummaryArr: [],
        bankInfo: [],
        inEntry: true,
        outEntry: true,
        createUserStatus: 0,
        examUserStatus: 0,
      },
    }
  },
  methods:{
    getList(){
      mergeInfo({}).then(res=>{
        let merge = res.data.data.info
        this.mergeSetInfo.in = String(merge.in)
        if(merge.inEntry == 1){
          this.mergeSetInfo.inEntry = true
        }else{
          this.mergeSetInfo.inEntry = false
        }
        this.mergeSetInfo.out = String(merge.out)
        if(merge.outEntry == 1){
          this.mergeSetInfo.outEntry = true
        }else{
          this.mergeSetInfo.outEntry = false
        }

        if(merge.bankEntry == 1){
          this.mergeSetInfo.bankEntry = true
        }else{
          this.mergeSetInfo.bankEntry = false
        }
        if(merge.dealingsEntry == 1){
          this.mergeSetInfo.dealingsEntry = true
        }else{
          this.mergeSetInfo.dealingsEntry = false
        }
        
        this.mergeSetInfo.bank = String(merge.bank)
        // if(merge.bankEntry == 1 && merge.dealingsEntry != 1){
        //   this.mergeSetInfo.bankInfo = ['银行存款']
        // }else if(merge.dealingsEntry == 1 && merge.bankEntry != 1){
        //   this.mergeSetInfo.bankInfo = ['往来科目']
        // }else if(merge.dealingsEntry == 1 && merge.bankEntry == 1){
        //   this.mergeSetInfo.bankInfo = ['银行存款','往来科目']  
        // }else{
        //   this.mergeSetInfo.bankInfo = []  
        // }

        this.mergeSetInfo.examUser = merge.examUser
        this.mergeSetInfo.createUser = merge.createUser
        this.mergeSetInfo.examUserStatus = merge.examUserStatus
        this.mergeSetInfo.createUserStatus = merge.createUserStatus
        this.mergeSetInfo.timeStatus = merge.timeStatus

        let arrIn = []
        merge.inSummary.split(',').map(v=>{
          if(v && v != "默认业务类型"){
            arrIn.push(v)
          }
        })

        let arrOut = []
        merge.outSummary.split(',').map(v=>{
          if(v && v != "默认业务类型"){
            arrOut.push(v)
          }
        })
        console.log(arrIn)

         let arrBank = []
        merge.bankSummary.split(',').map(v=>{
          if(v && v != "默认业务类型"){
            arrBank.push(v)
          }
        })


        this.mergeSetInfo.outSummaryArr = arrOut
        this.mergeSetInfo.inSummaryArr = arrIn
        this.mergeSetInfo.bankSummaryArr = arrBank

        console.log(this.mergeSetInfo)
        this.dialogFormVisibleSetting = true
        console.log(res);
      })
    },
    mergeOk() {
      this.mergeSetInfo.in = this.mergeSetInfo.in * 1
      this.mergeSetInfo.out = this.mergeSetInfo.out * 1
      this.mergeSetInfo.bank = this.mergeSetInfo.bank * 1
      if(this.mergeSetInfo.inEntry){
        this.mergeSetInfo.inEntry = 1
      }else{
        this.mergeSetInfo.inEntry = 0
      }
      if(this.mergeSetInfo.outEntry){
        this.mergeSetInfo.outEntry = 1
      }else{
        this.mergeSetInfo.outEntry = 0
      }
      
      if(this.mergeSetInfo.bankEntry){
        this.mergeSetInfo.bankEntry = 1
      }else{
        this.mergeSetInfo.bankEntry = 0
      }
      if(this.mergeSetInfo.dealingsEntry){
        this.mergeSetInfo.dealingsEntry = 1
      }else{
        this.mergeSetInfo.dealingsEntry = 0
      }
      
      // if(this.mergeSetInfo.bankInfo.indexOf("银行存款") >= 0){
      //   this.mergeSetInfo.bankEntry = 1
      // }else{
      //   this.mergeSetInfo.bankEntry = 0
      // }
      // if(this.mergeSetInfo.bankInfo.indexOf("往来科目") >= 0){
      //   this.mergeSetInfo.dealingsEntry = 1
      // }else{
      //   this.mergeSetInfo.dealingsEntry = 0
      // }

      this.mergeSetInfo.inSummary = this.mergeSetInfo.inSummaryArr.join(',')
      this.mergeSetInfo.outSummary = this.mergeSetInfo.outSummaryArr.join(',')
      this.mergeSetInfo.bankSummary = this.mergeSetInfo.bankSummaryArr.join(',')
      if((this.mergeSetInfo.inSummary.match('开票日期') || this.mergeSetInfo.inSummary.match('单位名称') || this.mergeSetInfo.inSummary.match('开票项目') || this.mergeSetInfo.inSummary.match('金额') || this.mergeSetInfo.inSummary.match('发票号码')) && this.mergeSetInfo.in != 0){
        this.$message.error('自定义凭证摘要不可合并凭证！');
        return
      }
      if((this.mergeSetInfo.outSummary.match('开票日期') || this.mergeSetInfo.outSummary.match('单位名称') || this.mergeSetInfo.outSummary.match('开票项目') || this.mergeSetInfo.outSummary.match('金额') || this.mergeSetInfo.outSummary.match('发票号码')) && this.mergeSetInfo.out != 0){
        this.$message.error('自定义凭证摘要不可合并凭证2！');
        return
      }
      mergeSet(this.mergeSetInfo).then(res=>{
        this.$message({
          message: '修改成功',
          type: 'success'
        });
      })

      console.log(this.mergeSetInfo)
      this.dialogFormVisibleSetting = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.right_title{
  width: 100%;
  margin: 0 auto;
}
.right_title h5{
  font-weight: normal;
}
.right_title p{
  font-size: 14px;
  color: red !important;
  text-align: left !important;
}
.item_radio,.one_item{
  width: 100%;
  padding-left: 30px;
  height: 25px;
}
.item_radio2{
  width: 100%;
  padding-left: 60px;
}
.item_lists{
  width: 100%;
  padding-left: 60px;
}
.el-radio-group{
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>