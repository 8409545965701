<template>
  <div class="app-container none-border" v-loading="loading2">
    <div class="top_btns">
      <div class="left_box">
        <el-input size="small" style="width:120px" v-model="listQuery.voucherNo" placeholder="请输入凭证号" @keyup.enter="getList"></el-input>
        <el-button size="small" type="primary"   @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon> <span  > 搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:21px 0px 0px 16px;height:170px;width:430px" class="styleForm">
            <el-form-item label="账期 ：" :label-width="formLabelWidth" prop="taxId">
              <el-date-picker size="small" v-model="listQuery.period" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYY-MM" style="width:150px"></el-date-picker>
            </el-form-item>
            <el-form-item label="审核状态 ：" :label-width="formLabelWidth" prop="taxId">
              <el-radio-group v-model="listQuery.checkStatus" class="ml-4">
                <el-radio :label="2" size="small">未审核</el-radio>
                <el-radio :label="1" size="small">已审核</el-radio>
                <el-radio :label="0" size="small">全部发票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="会计期间 ：" :label-width="formLabelWidth">   
              <el-date-picker size="small" placeholder="开始账期" v-model="listQuery.beginTime" style="width:110px" type="month" value-format="YYYYMM" format="YYYY-MM"></el-date-picker>
              <span>-</span>
              <el-date-picker size="small" placeholder="结束账期" v-model="listQuery.endTime" style="width:110px" type="month" value-format="YYYYMM" format="YYYY-MM"></el-date-picker>
            </el-form-item>
            <el-form-item label="凭证来源 ：" :label-width="formLabelWidth">
              <el-select v-model="listQuery.fromType" placeholder="请选择来源" size="small">
                <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <qzf-video vid="4467be5374cd64dcec5917c6bb165b4a_4"></qzf-video>

        <span style="margin-right:10px">共{{total}}条凭证</span>
        <qzf-button :loading="loading" size="small" type="primary" @success="becomeVoucher" style="margin-right:10px">
          <el-icon><Ticket /></el-icon><span  > 一键生成凭证</span>
        </qzf-button>
        <el-dropdown split-button type="success" @click="addVoucher" size="small" style="margin-right:10px" :hide-on-click="false">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span size="small" @click="openCom">导入</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button size="small" type="danger" @success="delAll()"   >
          <el-icon><Delete /></el-icon> <span  >批量删除</span>
        </qzf-button>
        <el-dropdown type="success" size="small" @command="handleCommand1" style="margin-left:6px;margin-right:6px" split-button v-if="$buttonStatus('pz_jzhdsy')">
          <span @click="carryForward"><icon class="el-icon-thumb"></icon> 一键结转</span>
          <template #dropdown>
            <el-dropdown-menu>
            <el-dropdown-item command="b">工资结转成费用</el-dropdown-item>
            <el-dropdown-item command="d">固定资产累计折旧</el-dropdown-item>
          </el-dropdown-menu>
          </template>
        </el-dropdown>
        <settings  :type="['code_onkeyvoucher_salary', 'code_onkeyvoucher_asset']"></settings>
        <el-dropdown @command="handleCommand" size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="a">
                <span>习惯设置</span>
              </el-dropdown-item>
              <el-dropdown-item command="b">
                <span>一键排序</span>
              </el-dropdown-item>
              <el-dropdown-item command="c">
                <span>日期排序</span>
              </el-dropdown-item>
              <el-dropdown-item command="d">
                <span>手动合并</span>
              </el-dropdown-item>
              <el-dropdown-item @click="dayin()">
                <span >打印</span>
              </el-dropdown-item>
              <el-dropdown-item @click="daochuVoucher()">
                <span >导出</span>
              </el-dropdown-item>
              <el-dropdown-item @click="drag()">
                <span >拖动排序</span>
              </el-dropdown-item>
              <el-dropdown-item @click="huidui()">
                <span >结转汇兑损益</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div>
      <el-table stripe :data="list" @sort-change="changeSort" v-loading="loading1" style="width: 100%"  border :height="contentStyleObj" @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column align="center" prop="voucherNo" label="凭证字号" width="70">
          <template #default="scope">
            <i style="cursor:pointer;color:#39b0d2" class="iconfont icon-pingzheng"  @click="handleUpdate(scope.row)"></i>
            <span @click="paixu(scope.row)" style="cursor:pointer;color:#39b0d2;position:relative;top:-1px;margin-left:3px"> {{ scope.row.voucherNo }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" sortable prop="voucherDate" label="日期" width="150">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.voucherDate, "{y}-{m}-{d}")}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="摘要" width="250">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.voucherItem" :key="index">
              {{ item.summary }}
            </p>
          </template>
        </el-table-column>

        <el-table-column align="center" label="科目" width="250">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.voucherItem" :key="index">
              {{item.subjectName + item.fzhsName}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="借方">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.voucherItem" :key="index">
              {{item.inAmount}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="贷方">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.voucherItem" :key="index">
              {{item.outAmount}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="billCount" label="附件张数" width="60"></el-table-column>
        <el-table-column align="center" prop="fromType" label="凭证来源"  width="80">
          <template #default="scope">
            <span>{{$VoucherSourceFilter(scope.row.fromType)}}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="createName" label="制作人"></el-table-column>
        <el-table-column align="center" prop="checkName" label="审核人"></el-table-column>
        <!-- <el-table-column align="center" prop="checkStatus" label="审核状态"></el-table-column> -->
        <el-table-column label="审核状态" align="center"> 
          <template #default="scope">
            <span v-if="scope.row.checkStatus == 0" style="color: red;">未审核</span>
            <span v-else style="color: #67c23a;">已审核</span>
          </template>
        </el-table-column>
        
        <el-table-column align="center" prop="address" label="操作" width="180">
          <template #default="scope">
            <qzf-button @success="handleUpdate(scope.row)" size="small" type="text">编辑</qzf-button>
            <el-button @click="handleDup(scope.row)" size="small" type="text">复制</el-button>
            <qzf-button size="small" @success="delVou(scope.row)" type="text">删除</qzf-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <div>
      <div class="bottom_button">
        <qzf-button size="small" type="primary" @success="voucherSH" plain >
          <el-icon><Stamp /></el-icon><span  > 一键审核</span>
        </qzf-button>
        <qzf-button size="small" type="primary" @success="voucherFSH" plain >
          <el-icon><Stamp /></el-icon><span  > 一键反审核</span>
        </qzf-button>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    <habitualSetting ref="habitualSetting"></habitualSetting>
    <!-- 手动合并弹窗 -->
    <manualMerge ref="manualMerge" :allselect="this.allselect" @success="getList"></manualMerge>
    <!-- <printVouch ref="printVouch" :name="this.name1"></printVouch> -->
    <printVouchPz ref="printVouchPz" :listIds="allselect" name="book_voucher" :listQuery1="this.listQuery"></printVouchPz>
    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="凭证"></qzf-import-back>
    <!-- 复制 -->
    <duplicate ref="duplicate" @success="getList"></duplicate>
    <!-- 导出 -->
    <exportFile ref="exportFile" :listIds="allselect" name="book_voucher" :listQuery1="this.listQuery"></exportFile>

    <oneKeyVoucher ref="oneKeyVoucher"></oneKeyVoucher>

    <dragSort ref="dragSort" @success = "getList()"></dragSort>

    <!-- 汇兑损益 -->
    <dialogDate ref="dialogDate" @handleSuccess='getList()'></dialogDate>



  </div>
</template>

<script>
import { salaryCarryOver,carryOverAsset } from '@/api/carryover.js'
import { voucherList, voucherDel , voucherSort ,editVoucherNo ,voucherLook } from "@/api/voucher.js";
import habitualSetting from "./components/habitualSetting.vue"
import manualMerge from "./components/manualMerge.vue"
import printVouchPz from "./components/printVouchPz.vue"
import duplicate from "./components/duplicate.vue"
import exportFile from "./components/exportFile.vue"
import oneKeyVoucher  from './components/oneKeyVoucher.vue';
import dragSort  from './components/dragSort.vue';
import dialogDate from "./components/dialogDate";

export default {
  name: "voucher",
  components:{
    habitualSetting,
    manualMerge,
    printVouchPz,
    duplicate,
    exportFile,
    oneKeyVoucher,
    dragSort,
    dialogDate
  },
  data() {
    return {
      loading: false,
      loading1: false,
      loading2: false,
      listQuery:{
        comId: this.$store.getters['user/comInfo'].comId * 1,
        orderBy: "period, replace(voucher_no,'记','')+0",
        limit:50,
        page:1,
        checkStatus:0
      },
      list: [],
      total: 0,
      select: [],
      allselect:[],
      allselect1: [],
      allselect2: [],
      fromOptions:[
        {
          value:'',
          label:'全部'
        },
        {
          value:'手工录入',
          label:'手工录入'
        },
        {
          value:'1',
          label:'进项'
        },
        {
          value:'2',
          label:'销项'
        },
        {
          value:'3',
          label:'费用'
        },
        {
          value:'4',
          label:'现金'
        },
        {
          value:'5',
          label:'票据'
        },
        {
          value:'6',
          label:'银行'
        },
        {
          value:'7',
          label:'工资'
        },
        {
          value:'8',
          label:'结转损益'
        },
        {
          value:'9',
          label:'计提税金'
        },
        {
          value:'10',
          label:'资产'
        },
        {
          value:'12',
          label:'存货结转'
        },
        {
          value:'13',
          label:'暂估回冲'
        },
        {
          value:'14',
          label:'暂估入库'
        },
        {
          value:'20',
          label:'进项认证'
        },
        {
          value:'30',
          label:'固定资产清理'
        },
        {
          value:'40',
          label:'汇兑损益'
        },
        {
          value:'100',
          label:'年末结转利润'
        },
      ],
      formLabelWidth: "100px",
    }
  },
  deactivated(){
    if(!this.$getCachedViews("voucher")){//销毁 mitt bus
      this.$bus.off("voucherUpdate")
    }
  },
  created() {
    this.contentStyleObj = this.$getHeight(260)
    this.getList()
    this.initBus()
  },
  methods:{
    drag(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$refs.dragSort.getList()
    },
    daochuVoucher(){
      this.$refs.exportFile.init()
    },
    changeSort(e){
      if(e.order == 'descending'){
        this.descending = 'desc'
        this.listQuery.orderBy = "period,voucher_date,replace(voucher_no,'记','')+0 desc"
      }else{
        this.descending = ''
        this.listQuery.orderBy = "period,voucher_date,replace(voucher_no,'记','')+0"
      }
      this.getList();
    },
    dayin() {
      this.$refs.printVouchPz.init()
    },
    initBus(){
      this.$bus.on("voucherUpdate", (val) => {
        this.getList()
      });
    },
    //复制
    handleDup(row) {
      this.$refs.duplicate.init(row.id)
    },
    handleSelectionChange(e){
      this.select = e
      let arr = [];
      e.map(v => {
        arr.push(v.id);
      });
      this.allselect = arr;
      let arr1 = [];
      e.map(v => {
        arr1.push({id:v.id,checkStatus:1});
      });
      this.allselect1 = arr1;
      let arr2 = [];
      e.map(v => {
        arr2.push({id:v.id,checkStatus:0});
      });
      this.allselect2 = arr2;
    },
    getList(){
      voucherList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
        }
        this.total = res.data.data.total
      })
    },
    //一键审核
    voucherSH() {
      if(this.allselect1.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.loading1 = true
      voucherLook(this.allselect1).then(res => {
        this.loading1 = false
        if(res.data.msg) {
          this.$qzfMessage("一键审核成功")
          this.getList()
        }
      })
    },
    //一键反审核
    voucherFSH() {
      if(this.allselect2.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.loading1 = true
      voucherLook(this.allselect2).then(res => {
        this.loading1 = false
        if(res.data.msg) {
          this.$qzfMessage("一键反审核成功")
          this.getList()
        }
      })
    },
    addVoucher() {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$store.dispatch('commons/setParam', {addVoucherId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    handleUpdate(e){
      if(e.checkStatus == 1){
        this.$qzfMessage("此条已审核,请先进行反审核", 1)
        return
      }
      this.$store.dispatch('commons/setParam', {addVoucherId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    delAll(){
      if(this.select.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.voucherDel(this.select)
    },
    delVou(row){
      this.voucherDel([{id: row.id}])
    },
    voucherDel(ids){
      this.$confirm("确认删除", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.loading1 = true
        voucherDel(ids).then(res=>{
          this.loading1 = false
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('invioceFeeUpdate')
            this.$bus.emit('bankUpdate')
            this.$bus.emit('cashUpdate')
            this.$bus.emit('billUpdate')
            this.$bus.emit('inventoryEstimated')
            this.getList()
          }
        })
      })
    },
    handleCommand(command) {
      if(command == 'a'){
        if(this.$checkSettleStatus() != ""){
          this.$message.error(this.$checkSettleStatus())
          return 
        }
        this.$refs.habitualSetting.getList()
      }else if(command == 'b'){
        if(this.$checkSettleStatus() != ""){
          this.$message.error(this.$checkSettleStatus())
          return 
        }
        voucherSort({}).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("操作成功")
            this.getList()
            this.$bus.emit('invioceInUpdate')
          }
        })
      }else if(command == 'c'){
        if(this.$checkSettleStatus() != ""){
          this.$message.error(this.$checkSettleStatus())
          return 
        }
        voucherSort({type:'data'}).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("操作成功")
            this.getList()
            this.$bus.emit('invioceInUpdate')
          }
        })
      }else if(command == 'd'){
        if(this.$checkSettleStatus() != ""){
          this.$message.error(this.$checkSettleStatus())
          return 
        }
        if(this.allselect.length == 0){
          this.$message.error('请选择要合并的凭证')
          return
        }
        this.$refs.manualMerge.dialogUp()
      }
    },
    //一键结转
    async carryForward() {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.loading2 = true
      await salaryCarryOver({}).then(res => {
        this.loading2 = false
        if(res.data.msg != 'success'){
          return
        }
      });
      await carryOverAsset({}).then(res => {
        this.loading1 = false
        if(res.data.msg != 'success'){
          return
        }
      })
      this.getList()
      this.$message({
        message:'结转成功',
        type:'success'
      })
    },
    //结转
    handleCommand1(command) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(command == 'b'){
        this.loading1 = true
        salaryCarryOver({}).then(res => {
          this.loading1 = false
          if(res.data.msg=='success'){
            this.$message({
              message:'结转成功',
              type:'success'
            })
          }else{
            this.$message.error(res.data.msg);
          }
          this.getList()
        })
      }else if(command == 'd'){
        this.loading1 = true
        carryOverAsset({}).then(res => {
          this.loading1 = false
          if(res.data.msg=='success'){
            this.$message({
              message:'结转成功',
              type:'success'
            })
          }else{
            this.$message.error(res.data.msg);
          }
          this.getList()
        })
      }
    },
    // 汇兑损益
    huidui(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$refs.dialogDate.dialogBox = true;
    },

    paixu(row){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$prompt('请输入凭证号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        editVoucherNo({id:row.id,voucherNo:value}).then(res=>{
          if(res.data.msg == "success"){
            this.getList();
            this.$qzfMessage("修改成功")
            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('invioceFeeUpdate')
            this.$bus.emit('bankUpdate')
            this.$bus.emit('cashUpdate')
            this.$bus.emit('billUpdate')
          }
        })
      })
    },
    becomeVoucher(){
      this.$refs.oneKeyVoucher.openDialog()
      return
      this.loading = true
      oneKeyVoucher({}).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.getList()
          this.$bus.emit('invioceInUpdate')
          this.$bus.emit('invioceOutUpdate')
          this.$bus.emit('invioceFeeUpdate')
          this.$bus.emit('assetsUpdate')
          this.$bus.emit('inventoryManageUpdate')
          this.$bus.emit('inventoryPeriodUpdate')
          this.$bus.emit('bankUpdate')
          this.$bus.emit('cashUpdate')
          this.$bus.emit('billUpdate')
        }
      })
    },
    openCom(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$refs.importBck.handImport()
    }
  }
}
</script>

<style scoped lang="scss">
 
.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.none-border p:first-child {
  border-top: none;
}
.top_btns {
  margin-bottom: 38px;
   .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.styleForm{
  .el-select{
    margin-left: 0px;
  }
}
.bottom_button{
  display: inline-block;
  margin-top: 20px;
}
</style>
<style>
.none-border .cell {
  padding: 0;
}
.none-border .el-table__row td {
  padding: 0 !important;
}

.none-border .cell {
  padding: 0;
  text-align: center;
}
</style>